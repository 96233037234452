import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { Row, Col, Button as BootstrapButton } from 'react-bootstrap'
import { Link } from 'gatsby'

import { GiovaniliQuery } from '../../types/graphql'

import { Container } from '../../components/Container'
import { NewsFeedGiovanili } from '../../containers/NewsGiovanili'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { Youth } from '../../containers/Youth'

import * as classes from './giovanili.module.scss'

type YouthTeamsProps = {
  data: GiovaniliQuery
}

const teamImages = [
  'https://admin.pallacanestroreggiana.it/wp-content/uploads/2025/02/UNDER-15.jpg',
  'https://admin.pallacanestroreggiana.it/wp-content/uploads/2025/02/UNDER-17.jpg',
  'https://admin.pallacanestroreggiana.it/wp-content/uploads/2025/02/UNDER-19.jpg',
]

export default function YouthTeams({ data }: YouthTeamsProps) {
  return (
    <Youth cover={data.cover?.childImageSharp?.fluid as FluidObject}>
      <SEO title={'Squadre giovanili'} />

      <Container>
        <Section>
          <Row className={classes.categoriesRow}>
            {data.categories.nodes.map((node, index) => {
              const team = node.slug!
              const bgImage = teamImages[index % teamImages.length] // Ciclo sulle immagini

              return (
                <Col key={node.wordpressId!} className={classes.categoryCol}>
                  <div
                    className={classes.teamCard}
                    style={{ backgroundImage: `url(${bgImage})` }}
                  >
                    <BootstrapButton
                      as={Link}
                      to={`/giovanili/${team}`}
                      className={classes.categoryButton}
                    >
                      {node.name} ECCELLENZA
                      <br />
                    </BootstrapButton>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Section>

        <Section title={'Ultime news'}>
          <NewsFeedGiovanili preview />
        </Section>
      </Container>
    </Youth>
  )
}

export const query = graphql`
  query Giovanili {
    cover: file(name: { eq: "cover_giovanili" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  
    categories: allWordpressWpSquadra(
      filter: { 
        slug: { regex: "/^under-(15|17|19)-\\d{4}-\\d{4}$/" }
        parent_element: { name: { eq: "2024-2025" } }
      }
      sort: { fields: [slug], order: ASC }
    ) {
      nodes {
        slug
        name
        season: parent_element {
          name
        }
      }
    }
  }
`
